import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from "recoil";
//지우지 마시오 import i18n from './translation/i18n';
// eslint-disable-next-line no-unused-vars
import i18n from './translation/i18n';
import './index.css';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import { isBlank, isBooleanTrue } from './utils/utils';

export const retryLazy = (componentImport) =>
  lazy(async () => {
    const pageAlreadyRefreshed = JSON.parse(
      window.localStorage.getItem('pageRefreshed') || 'false'
    )
    try {
      const component = await componentImport()
      window.localStorage.setItem('pageRefreshed', 'false')
      return component
    } catch (error) {
      if (!pageAlreadyRefreshed) {
        window.localStorage.setItem('pageRefreshed', 'true')
        return window.location.reload()
      }
      throw error
    }
})

const App = retryLazy(() => import('./AppRedirected'));

// 환경 이름
const envName = process.env.REACT_APP_ENV_NAME;
console.log(`REACT_APP_ENV_NAME:${envName}`);

// 빌드 버전 (npm run build 시점에 삽입되는 환경변수임)
const gitVersion = process.env.REACT_APP_GIT_VERSION;

// import and initialize the Sentry module as early as possible, before initializing React
const dsn = process.env.REACT_APP_SENTRY_DSN;
if (!isBlank(dsn)) {
  // var initSentry = async ()=>{
  Sentry.init({
    dsn: dsn,
    environment: envName,
    release: gitVersion,
    integrations: [new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      )
    })],
  
    beforeSend(event, hint) {
      if (event.exception) {
        if (isBooleanTrue(process.env.REACT_APP_SENTRY_ERROR_BOUNDARY_ENABLED)) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            title: "문제가 있는 것 같습니다.",
            subtitle: "개발 팀에 통보되었습니다.",
            subtitle2: "아래에 무슨 일이 있었는지 알려주세요.",
            labelName: "이름",
            labelEmail: "이메일",
            labelComments: "어떤 문제였나요? (상세하게 적어주시면 감사하겠습니다)",
            labelSubmit: "크래시 리포트 제출하기",
            labelClose: "닫기",
            successMessage: "크래시 리포트가 전송되었습니다. 감사합니다",
          });
        }
      }
      return event;
    },
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  // console.log(`sentry enabled`);
  // };
  // initSentry();
}
// // console.log('cs__ sentry loaded')
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
// console.log('cs__ sentry routes loaded')
// 콘솔로그 Sentry breadcrumb 로 오버라이딩 (Sentry.init 보다 뒤에 있어야한다)
if (isBooleanTrue(process.env.REACT_APP_OVERRIDE_CONSOLE_TO_SENTRY_BREADCRUMB)) {
  console.log("log disabled");
  console.debug = function addSentryBreadcrumbDebug() {
    Sentry.addBreadcrumb({
      category: "console.debug.override",
      data: arguments,
      level: "debug",
    });
  };
  console.info = function addSentryBreadcrumbInfo() {
    Sentry.addBreadcrumb({
      category: "console.info.override",
      data: arguments,
      level: "info",
    });
  };
  console.log = function addSentryBreadcrumbLog() {
    Sentry.addBreadcrumb({
      category: "console.log.override",
      data: arguments,
      level: "log",
    });
  };
  console.warn = function addSentryBreadcrumbWarn() {
    Sentry.addBreadcrumb({
      category: "console.warn.override",
      data: arguments,
      level: "warn",
    });
  };
  console.error = function addSentryBreadcrumbError() {
    Sentry.addBreadcrumb({
      category: "console.error.override",
      // message: arguments,
      data: arguments,
      level: "error",
    });
  };
}

if (envName === "production" ||
    envName === "devqa" ||
    envName === "staging") {
  //프로덕션에만 적용되는 수정사항
  document.oncontextmenu = disableClick;
  function disableClick(e) {
      // if (e.button === 2) {
      //   e.preventDefault();
      //   console.log('Mouse Down')
      //   return true;
      // }
      e.preventDefault();
      e.stopPropagation();
      return false;
  }
  
}

console.log(`REACT_APP_GIT_VERSION:${gitVersion}`);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  {/* // <React.StrictMode> */}
  <RecoilRoot>
    {/* <ChakraProvider> */}
    <Suspense fallback={
      <div 
        style={
          {
            position: "absolute"
            , display: 'flex'
            , alignItems: 'center'
            , justifyContent: 'center'
            , height: '100%'
            , width: '100%'
          }
        }
      // style="position: absolute; display: flex; align-items: center; justify-content: center; height: 100%; width: 100%"
      >
      <div className="header-logo text-white animate-flicker"></div>
      </div>
    }>
      <App />
    </Suspense>
    {/* </ChakraProvider> */}
  </RecoilRoot>
  {/* // </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
