// import langkr from './lang_kr.json'
// import langen from './lang_en.json'
// import langjp from './lang_jp.json'
import { initReactI18next } from 'react-i18next'
import i18n from "i18next"
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
// const resources = {
    // ko: {
    //     translation: langkr
    // },
    // en: {
    //     translation: langen
    // },
    // ja: {
    //     translation: langjp
    // }
// }

var hashCode = function(str) {
    var hash = 0,
      i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

var gitHash = hashCode(process.env.REACT_APP_GIT_VERSION ?? "noversion");

//TODO 네임스페이스?
i18n
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .use(HttpBackend)
    .init(
        {
        //    resources: resources,
           fallbackLng: ['en'],  
           debug: false,
           keySeparator: false,
           interpolation: {
            escapeValue: false
           }
           ,backend: {
            loadPath: '/locales/lang_{{lng}}.json?cache_hash='+gitHash // 20KB정도 절약
            // ,allowMultiLoading:false
           }
        }
    )

export default i18n;